@import url('../../App.css');

.host-message {
    background-color: var(--jet);
    color: var(--light-gray)
}

.guest-message {
    background-color: var(--msg-color);
    color: var(--light-gray);
}

.h-screen-1\/2{
    height: 50vh;
}

.message-wrapper{
    max-width: 66.6667%;
}

@media (max-width: 640px) {
    .message-wrapper {
        max-width: 87%;
    }
}

.typing-dots {
    position: relative;
    width: 80px;
    height: 13px;
}

.typing-dots div {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--light-gray);
    animation: typing-dots 1.4s infinite ease-in-out both;
}

.typing-dots div:nth-child(1) {
    bottom: 8px;
    left: 8px;
    animation-delay: -0.32s;
}

.typing-dots div:nth-child(2) {
    bottom: 8px;
    left: 32px;
    animation-delay: -0.16s;
}

.typing-dots div:nth-child(3) {
    bottom: 8px;
    left: 56px;
}

@keyframes typing-dots {
    0%, 80%, 100% {
        transform: translateY(8px)
    }
    40% {
        transform: translateY(0)
    }
}

@keyframes slide-in {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.message-slide-in {
    animation: slide-in 0.5s ease-out;
}